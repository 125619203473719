(function ($) {
'use strict';

var $window = $(window);
var $header = $('#header');

var windowWidth = $window.width();

/**
 * Global Navigation toggle
 */
// var $globalNav = $('#global-nav');
var $navOpenBtn = $('#navOpenBtn');
var $navCloseBtns = $('#navCloseBtn, #navCloseLayer');

// Open
$navOpenBtn.on('click', function (e){
	e.preventDefault();
	$header.addClass('shown');
});

// Close
$navCloseBtns.on('click', function (e){
	e.preventDefault();
	$header.removeClass('shown');
});


/**
 * Sticky (Company)
 */
var sidebar = document.getElementById('sidebar');
if (sidebar) {
	var sticky = new Sticky('#sidebar');
}

/**
 * Smooth scroll
 */
$('a[href*="#"]:not([href="#"])').click(function() {
	if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 1000);
			return false;
		}
	}
});

})(jQuery)